.study-detail-design .table_main_table.first_table table tr td:first-child {
	width: auto;
}

.study-result-container .table_main_table {
	padding: 30px;
}

.study-result-container .table_main_table .box {
	padding-left: 2%;
	width: 25%;
	height: 100px;
	border-left: 1px solid rgb(235, 235, 235);
	display: inline-block;
	vertical-align: top;
}
.study-result-container .table_main_table .box .txt {
	height: 50px;
	font-size: 16px;
}
.study-result-container .table_main_table .box .num {
	font-family: Helvetica Bold;
	font-size: 36px;
	color: rgb(94, 106, 114);
}
.study-result-container .table_main_table .box .num .blue {
	color: #3980d8;
}

.tbl-strip {
	font-size: 15px;
	width: 100%;
}
.tbl-strip tbody tr {
	border-bottom:.5px solid #BBBBBB;
}
.tbl-strip tr {
	height: 40px;
}

.tbl-strip td {
	text-align: center;
}

.tbl-strip tr td .first {
	min-width: 200px;
	text-align: left;
	padding-left: 20px;
	width: auto;
}
.tbl-strip tr td .last-col {
	min-width: 200px;
	text-align: right;
	padding-right: 20px;
	width: auto;
}
.tbl-strip tr td .last-ele {
	min-width: 200px;
	text-align: right;
	padding-right: 40px;
	width: auto;
}
.tbl-strip tr td:first-child {
	padding: 0px;
}

.tbl-strip .tbl-head {
	background: #f4f4f4;
	color: #40424c;
}

.tbl-strip .tbl-head td {
	padding: 0px 10px;
	background: #f4f4f4;
}
.tbl-strip tbody tr:nth-child(2n) {
	/* background-color: #fbfbfb; */
}

.study-validity-tbl-container {
	padding-left: 0px;
	display: block;
    overflow: auto;
    white-space: nowrap;
	height: 60vh;
}
.study-validity-tbl-container:focus {
	outline: none;
}

.study-validity-tbl-container .tbl-strip tbody tr {
	background-color: #fff;
}
.study-validity-tbl-container .tbl-strip .tbl-head td{
	position: sticky;
	top:0;
	z-index:1;
}

.study-validity-tbl-container .tbl-strip .tbl-head td.first{
	position: sticky;
	left:0;
	top:0;
	z-index:2;
}

.study-validity-tbl-container .tbl-strip tbody tr td.first{
	background-color: #fff;
	position: sticky;
	left:0;
}
.study-validity-details-tbl-container {
	padding-left: 0px;
	display: block;
    overflow: auto;
    white-space: nowrap;
}
.study-validity-drilldown-tbl-container {
	padding-left: 0px;
	display: block;
    white-space: nowrap;
	overflow-x:scroll;
}
.study-validity-item-left {
	text-align: left;
	margin-left: 0%;
}
.dropdown-menu > li.selected > a {
	background-color: #f4f4f4;
}

.dropdown-item.selected {
	background-color: #f4f4f4;
}

.edit-study-validity-option {
	padding: 5px;
	height: 40px;
}
.edit-study-validity-option .txt,
.edit-study-validity-option .drpdwn {
	display: inline-block;
	vertical-align: middle;
}
.edit-study-validity-option .txt {
	padding: 5px 10px 5px 50px;
	width: 135px;
}

.edit-study-validity-option .drpdwn {
	width: 225px;
}
.edit-study-validity-option .drpdwn .btn {
	font-size: 14px;
}
.edit-study-validity-option .drpdwn .btn:first-child {
	width: 250px;
	text-align: left;
}

.Select:focus {
	border: none;
}
.Select-input {
	height: 24px;
}
.Select-placeholder,
.Select--single > .Select-control .Select-value {
	line-height: 24px;
	color: #000;
}
.Select-control {
	height: 24px;
}
.Select-control > *:last-child {
	padding: 0 5px;
}

.Select-arrow-zone {
	border-left: 1px solid #e5e5e5;
}

.footer {
	padding: 20px;
}

.btn-action {
	width: 135px;
	font-size: 16px;
	padding: 7px 20px;
	border: none;
	border-radius: 3px;
	background: #00b5e0;
	color: #fff;
	margin-left: 10px;
}
.btn-cancel {
	width: 135px;
	font-size: 16px;
	padding: 7px 20px;
	border: none;
	border-radius: 3px;
	background: #00b5e0;
	color: #fff;
	margin-left: 10px;
}
.first-Cap {
	text-transform: capitalize;
}

#patient-list-accordion .patient-hostory {
	padding: 10px 13px 7px 100px;
}

.sort_container {
	display: inline-block;
	margin-left: 0px;
	height: 28px;
	vertical-align: middle;
}

.sort-wrapper {
	height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.icon_sort {
	height: 9px;
	width: 10px;
	flex-basis: 50%;
	display: flex;
	/* float: right; */
	margin-left: 10px;
	cursor: pointer;
	/* margin-top: 4px; */
	/* color: #82b2a2; */
	/* display: initial; */
	/* font-size: 12px; */
}

.icon_sort .arrow-up.sorted {
	/* background-image: url('../images/sort-filled.svg');
	background-repeat: no-repeat;
	background-size: contain; */
	height: 10px;
	width: 100%;
	display: flex;
}
.icon_sort .arrow-up.unsorted {
	/* background-image: url('../images/sort-unfilled.svg');
	background-repeat: no-repeat;
	background-size: contain;
	transform: rotate(0deg); */
	height: 10px;
	width: 100%;
	display: flex;
	color:#D8D8D8;
}

.icon_sort .arrow-bottom.sorted {
	/* background-image: url('../images/sort-filled.svg');
	background-repeat: no-repeat;
	background-size: contain;
	transform: rotate(180deg); */
	height: 10px;
	width: 100%;
	display: flex;
}
.icon_sort .arrow-bottom.unsorted {
	/* background-image: url('../images/sort-unfilled.svg');
	background-repeat: no-repeat;
	background-size: contain;
	transform: rotate(180deg); */
	height: 10px;
	width: 100%;
	display: flex;
	color:#D8D8D8;
}
.Select.uniqueTriaArm.custom.select_cohart.error .Select-control {
	border: 1px solid #ea5848;
}

#addManualAnnotationPopover {
	padding-left: 42px;
}

.addstudy {
	padding: 15px 0;
}
.addstudy .study_input {
	width: 80%;
    padding-left: 5px;
    display: inline-block;
}

.addstudy .study_name {
    padding:10px 0;
    display:flex;
	/* align-items: center; */
}

.addstudy .study_name .lbl {
    display: inline-block;
}
.addstudy .study_name .study_txt.error {
	border: 1px solid #ea5848;
}
.addstudy .study_name label {
	color: #40424c;
	font-family: "roboto-regular";
	font-size: 16px;
	padding-right: 0;
	font-weight: normal;
	display: inline-block;
	margin-bottom: 0px;
	padding-top: 5px;
}

.addstudy .study_name .lbl_name {
	font-size: 20px;
	display: inline-block;
	padding-left: 14px;
	padding-top: 3px;
}

.addstudy .study_name .study_txt {
	/* border: 1px solid #ccc; */
	/* border-radius: 3px; */
	font-size: 14px;
	font-weight: normal;
	padding-left: 12px;
	width: 100%;
	box-sizing: border-box;
    border: 1px solid rgba(156, 156, 156, .5);
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 12%);
	height: 35px;
}
.addstudy .study_name .study_txt:focus{
	outline: none;
}
.addstudy .cohorts_group .icons img {
	margin: 6px 10px 0 0;
	width: 24px;
}

.cohorts_group {
    background: #f4f4f4;
    height: 40px;
    padding: 0;
    margin-bottom: 10px;
}


.cohorts_group .lbl {
	color: #555;
	font-size: 16px;
	font-weight: normal;
	height: 40px;
	margin: 0;
	/* padding-bottom: 9px; */
    padding-top: 9px;
    padding-left: 12px;
}

.study_name.cohort_study_name .cohort_label {
	padding-left: 0;
	width: 180px;
	padding-right: 5px;
    padding-top: 3px;
    display: inline-block;
    text-align: right;
}

.addstudy .less_btn {
    display:inline-block;
    padding-right:10px;
    cursor: pointer;
}
.addstudy .add-row  {
    padding-left: 49px;
    padding-bottom: 30px;
}
.addstudy .add-row img {
    width:24px
}

.cohort_study_name .cohort_select{
    display:inline-block;
    width:76%;
}
.cohort_select.text-left,
.cohort_select_div.text-left {
	padding: 0;
}

.cohort_select_div.text-left .Select.uniqueTriaArm.custom.Select--single.is-clearable.is-searchable {
	margin: 0;
}


.cohort_select.text-left span.Select-clear {
	display: none;
}

.cohort_select_div.text-left span.Select-clear {
	display: none;
}

.addstudy .table_main_table {
    padding-left:176px;
}
.sv-filter-dropdown {
	max-height: 300px;
	overflow: auto;
}

.study-status tr.added {
	border-left: solid whitesmoke 10px;
}

.study-status tr.inprogress {
	border-left: solid #fdfd96 10px;
}

.study-status tr.processingcomplete {
	border-left: solid #009e00 10px;
}

.study-status tr.processingfailed {
	border-left: solid #ff0000 10px;
}

.study-status tr.annotationcreated {
	border-left: solid #009e00 10px;
}

.study-status tr.annotationfailed {
	border-left: solid #ff0000 10px;
}

.tbl-study-admin {
	width: 100%;
}

.tbl-study-admin th, .tbl-study-admin td {
	text-align: center;
}

.tbl-study-admin th {
	background: #efefef !important;
	padding: 8px;
	font-weight: 500;
}
/* .tbl-study-admin th:first-child {
	padding-left: 21px;
} */
/* .tbl-study-admin td {
	padding: 8px;
}
.tbl-study-admin td:first-child {
	padding-left: 24px;
} */
.tbl-study-admin tr:nth-child(even) {
	background: #fbfbfb;
}
.tbl-study-admin tr:hover {
	background: #e8f0f8;
}